import AttachmentDragNDropInputComponent from "./attachmentDragNDropInput";
import CheckboxInputComponent from "./checkboxInput";
import DateInputComponent from "./dateInput";
import QuillInputComponent from "./quilleditor";
// import HorizontalDateInputComponent from "./horizontalInput/horizontalDateInput";
import HorizontalLabelInputComponent from "./horizontalInput/horizontalLabelInput";
// import HorizontalPhoneInputComponent from "./horizontalInput/horizontalPhoneInput";
// import LabeledRadioInputComponent from "./labeledRadioInput";
// import LabeledPhoneInputComponent from "./labledPhoneInput";
// import OtpInputComponent from "./otpInput";
import ProfilePicInputComponent from "./profilePicInput";
import SelectInputComponent, { SelectBox } from "./selectInput";
import LabeledInputComponent from "./labeledInput";
import SearchInputComponent from "./searchInput";

export const SelectBoxStyle = SelectBox;

export const LabeledInput = LabeledInputComponent;
export const SearchInput = SearchInputComponent;
export const SelectInput = SelectInputComponent;
export const CheckboxInput = CheckboxInputComponent;
export const AttachmentDragNDropInput = AttachmentDragNDropInputComponent;
export const HorizontalLabelInput = HorizontalLabelInputComponent;
// export const HorizontalPhoneInput = HorizontalPhoneInputComponent;
// export const LabeledPhoneInput = LabeledPhoneInputComponent;
export const ProfilePicInput = ProfilePicInputComponent;
export const DateInput = DateInputComponent;
export const QuillInput = QuillInputComponent;

// export const HorizontalDateInput = HorizontalDateInputComponent;
// export const LabeledRadioInput = LabeledRadioInputComponent;
// export const LabeledOtpInput = OtpInputComponent;
