import ManageSettingsComponent from "./manageSettings";
import ManageUserRightsComponent from "./manageUserRights";
import ManagePrivacyPolicyComponent from "./managePrivacyPolicy";
import ManageSkillsComponent from "./manageSkills";
import ManageCategoryComponent from "./manageCategory";
import ManageEducationComponent from "./manageEducation";
import ManageCountryComponent from "./manageCountry";
import ManageSectorComponent from "./manageSector";
import ManageTagComponent from "./manageTag";
import ManageLanguageComponent from "./mangeLanguage";
import ManageOpportunity from "./manageOpportunity";
import ManageTenderCategory from "./manageTenderCategory";
import NewPostResource from "./manageSettings/postNewResources";
import FinancialTools from "./manageFinancialTools";
import ManageListingCompany from "./manageListingCompany";
import ManageTestimonials from "./manageTestimonial";
import PostTestimonials from "./manageTestimonial/postManageTestimonials";
import ManageNewsLetter from "./manageNewsLetter";
import ManageAdSenseComponent from "./manageAdSense";
import Report from "./manageReports";
import ManageFAQ from "./manageFAQ";
import ShowFAQ from "./manageFAQ/createFQL/showFAQ";
export const showFAQ = ShowFAQ;
export const manageFAQ = ManageFAQ;
export const manageReports = Report;
export const manageNewsLetter = ManageNewsLetter;
export const postTestimonials = PostTestimonials;
export const manageTestimonials = ManageTestimonials;
export const manageListingCompany = ManageListingCompany;
export const financialTools = FinancialTools;
export const ManageSettings = ManageSettingsComponent;
export const ManageUserRights = ManageUserRightsComponent;
export const ManagePrivacyPolicy = ManagePrivacyPolicyComponent;
export const ManageSkills = ManageSkillsComponent;
export const ManageCategory = ManageCategoryComponent;
export const manageEducation = ManageEducationComponent;
export const manageCountry = ManageCountryComponent;
export const manageSector = ManageSectorComponent;
export const manageTag = ManageTagComponent;
export const ManageLanguage = ManageLanguageComponent;
export const manageOpportunity = ManageOpportunity;
export const manageTenderCategory = ManageTenderCategory;
export const newPostResource = NewPostResource;
export const ManageAdSense = ManageAdSenseComponent;
